import React from "react";
import { Route, Switch, useRouteMatch, useHistory, useParams, useLocation, matchPath, Link } from "react-router-dom";
import { ElxActionButton, IElxColumn, getLogger, ElxTableContainer, IElxContainerProps } from "@elixir/fx";
import { SelectionMode, Stack, mergeStyleSets, DefaultEffects, Text, Panel, PanelType } from "office-ui-fabric-react/lib/index";

import "./custom.css";

import { GlobalPanelContext, GlobalPanelActionTypes, GlobalPanelHeaders } from "./contexts/GlobalPanelContext";
import { ExceptionRequestClient, ServicesInformationClient } from "./ApiClients/ApiClient";
import { NewExceptionFormContextProvider } from "./contexts/NewExceptionFormContext";
import { NewExceptionForm } from "./views/NewExceptionForm";
import { ExceptionFormContextProvider } from "./contexts/ExceptionFormContext";
import { ExceptionForm } from "./views/ExceptionForm";
import { STATUS_MAP, IExceptionRequest, IdNamePair, IExceptionRequestTableRow, DeploymentSystem } from "./Types";
import { camelToUpperCase, dateFormatter } from "./Utils";
import { ContentWithTooltip } from "./Components/ContentWithTooltip";
import PanelStyles from "./styles/PanelStyles";

const classNames = mergeStyleSets({
    exceptionRequestsTable: {
        boxShadow: DefaultEffects.elevation8,
    },
});

const ExceptionRequestsTable: React.FC = () => {
    const routeMatch = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const exceptionRequestClient = new ExceptionRequestClient();
    const servicesInfromationClient = new ServicesInformationClient();
    const columnFields = [
        { key: "title" },
        { key: "serviceName" },
        { key: "deploymentOfOrigin", displayName: "Rollout Id" },
        { key: "status" },
        { key: "requestDate" },
        { key: "exceptionRequestId" },
        { key: "requestorEmail" },
        { key: "approverEmails" },
        { key: "approvedBy" },
    ];
    const [isLoading, setIsLoading] = React.useState(false);
    const [exceptionRequestsData, setExceptionRequestsData] = React.useState<IExceptionRequest[] | null>(null);
    const [serviceMap, setServiceMap] = React.useState<IdNamePair[] | null>(null);
    const [hasInitialized, setHasInitialized] = React.useState(false);

    const initializeData = async () => {
        setIsLoading(true);
        try {
            //Get exception requests
            var exceptionRequests = await exceptionRequestClient.getAllExceptionRequests();
            var services: IdNamePair[] = [];

            //Get unique service name pairs
            if (exceptionRequests && exceptionRequests.length) {
                //bug#30336022- Adding services length check so that the code doesn't error out when the service is not associated to a request.
                const serviceIds = exceptionRequests
                    .filter((exceptionRequest) => exceptionRequest.services && exceptionRequest.services.length > 0)
                    .map((exceptionRequest) => exceptionRequest.services![0].serviceId)
                    .filter((value, index, self) => self.indexOf(value) === index);
                if (serviceIds != null) {
                    let serviceIdsChunks = [];
                    while (serviceIds.length > 0) {
                        serviceIdsChunks.push(serviceIds.splice(0, 100));
                    }
                    await Promise.all(serviceIdsChunks.map(serviceIdsChunk => servicesInfromationClient.getServicesByIds(serviceIdsChunk)))
                        .then((values) => {
                            if (values) {
                                values.forEach(value => services.push(...value));
                            }
                        });
                }
            }

            //sort by date descending
            var exceptionRequestsSorted = exceptionRequests.sort((a, b) => new Date(b.requestDate).getTime() - new Date(a.requestDate).getTime());

            setExceptionRequestsData(exceptionRequestsSorted);
            setServiceMap(services);
        } catch (e) {
            console.error(e);
            getLogger().error(e);
        }
        setIsLoading(false);
        setHasInitialized(true);
    };

    React.useEffect(() => {
        initializeData();
    }, []);
    React.useEffect(() => {
        if (location.pathname === "/home" && hasInitialized) {
            initializeData();
        }
    }, [location.pathname]);

    const getDefaultColumn = (col: { key: string; displayName?: string }): IElxColumn => {
        const defaultColumn: IElxColumn = {
            key: col.key,
            name: col.displayName || camelToUpperCase(col.key),
            fieldName: col.key,
            minWidth: 150,
            maxWidth: 300,
            isResizable: true,
        };

        return defaultColumn;
    };

    const getCustomRenderer = (item: any, index: number, column: IElxColumn): JSX.Element | string => {
        switch (column.key) {
            case "title":
                return item.title ? (
                        <Link style={{ textDecoration: "underline" }} to={`/home/Exception/${item.exceptionRequestId}`}>
                            {item.title}
                        </Link>
                ) : (
                        <Link style={{ textDecoration: "underline" }} to={`/home/Exception/${item.exceptionRequestId}`}>
                            {item.deploymentOfOrigin ? `${DeploymentSystem.Ev2} - ${item.deploymentOfOrigin}` : item.exceptionRequestId}
                        </Link>
                );
            default:
                return (<Text>{item[column.key]}</Text>);
        }
    };

    const columns: IElxColumn[] = columnFields.map((field) => {
        return {
            ...getDefaultColumn(field),
            onRender: (item, index, column) => {
                return (
                    <ContentWithTooltip showOnParentOverflow={true}>
                        {getCustomRenderer(item, index!, column!)}
                    </ContentWithTooltip>
                );
            },
        } as IElxColumn;
    });

    const containerProps: IElxContainerProps = {
        headerText: "Exception Requests Table",
        onRenderHeader: () => {
            return (
                <Stack tokens={{ childrenGap: 15 }}>
                    <Text variant="xxLarge">Exception Requests</Text>
                    <Stack horizontal>
                        <ElxActionButton
                            onClick={() => history.push(`${routeMatch.url}/NewException`)}
                            iconProps={{ iconName: "Add" }}
                            text="New Exception Request"
                        />
                    </Stack>
                </Stack>
            );
        },
        isLoading: isLoading,
        subActions: [<ElxActionButton key={1} text="Refresh" iconProps={{ iconName: "Refresh" }} onClick={() => initializeData()} />],
        styles: {
            //To show new exception request button
            header: {
                overflow: "visible",
                marginBottom: "50px",
            },
        },
    };

    const items : IExceptionRequestTableRow[] = exceptionRequestsData
        ? exceptionRequestsData.map((data, i) => {
              // build a list of approver emails to display
              var approverEmails = data.approvers ? data.approvers.map(approver => approver.approverEmail).join(", ") : '';

              return {
                  title: data.title,
                  exceptionRequestId: data.exceptionRequestId,
                  requestDate: dateFormatter.format(new Date(data.requestDate)),
                  serviceName: serviceMap?.find((idNamePair) => data.services && data.services?.length>0 && data.services![0].serviceId === idNamePair.id)?.name,
                  status: STATUS_MAP[data.status],
                  requestorEmail: data.requestorEmail || "-",
                  deploymentOfOrigin: data.deploymentOfOrigin || "-",
                  approverEmails: approverEmails,
                  approvedBy: data.approvedBy || "-",
              };
          })
        : [];

    return (
        <ElxTableContainer
            containerProps={containerProps}
            tableProps={{
                items,
                columns,
                selectionMode: SelectionMode.none,
                className: classNames.exceptionRequestsTable,
            }}
            searchBoxProps={{ disabled: false }}
        />
    );
};

const FromDeployment: React.FC = () => {
    const globalPanelContext = React.useContext(GlobalPanelContext);
    const params = useParams<{ id: string }>();
    const history = useHistory();
    const exceptionRequestClient = new ExceptionRequestClient();

    const initializeData = async () => {
        try {
            globalPanelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: true });
            //create new exception, then open Exception editor
            globalPanelContext.dispatch({
                type: GlobalPanelActionTypes.SET_HEADER,
                payload: `Creating Exception Request from Deployment ID: ${params.id}`,
            });
            const newExceptionRequest = await exceptionRequestClient.createNewExceptionRequestFromDeployment(params.id);
            history.replace(`/home/Exception/${newExceptionRequest.exceptionRequestId}`);
        } catch (e) {
            getLogger().error(e);
            history.replace("/home");
        }
        globalPanelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: false });
    };

    //Initialize data
    React.useEffect(() => {
        initializeData();
    }, []);

    return <></>;
};

export const App: React.FC = () => {
    const { state, dispatch } = React.useContext(GlobalPanelContext);
    const routeMatch = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    //Programmatic Navigation for Global Panel
    React.useEffect(() => {
        const newExceptionPage = matchPath(location.pathname, {
            path: `${routeMatch.url}/NewException`,
            exact: true,
            strict: false,
        });
        const exceptionPage = matchPath<{ id: string }>(location.pathname, {
            path: `${routeMatch.url}/Exception/:id`,
            exact: true,
            strict: false,
        });
        const fromDeploymentPage = matchPath<{ id: string }>(location.pathname, {
            path: `${routeMatch.url}/fromDeployment/:id`,
            exact: true,
            strict: false,
        });

        dispatch({ type: GlobalPanelActionTypes.CLEAR_ERROR });

        if (newExceptionPage) {
            dispatch({ type: GlobalPanelActionTypes.SET_PANEL_SIZE, payload: PanelType.large });
            dispatch({ type: GlobalPanelActionTypes.SET_SHOW_PANEL, showPanel: true });
            dispatch({ type: GlobalPanelActionTypes.SET_HEADER, payload: GlobalPanelHeaders.NEW_EXCEPTION });
        } else if (exceptionPage) {
            dispatch({ type: GlobalPanelActionTypes.SET_PANEL_SIZE, payload: PanelType.medium });
            dispatch({ type: GlobalPanelActionTypes.SET_SHOW_PANEL, showPanel: true });
            dispatch({ type: GlobalPanelActionTypes.SET_HEADER, payload: GlobalPanelHeaders.EXCEPTION_DETAILS });
        } else if (fromDeploymentPage) {
            dispatch({ type: GlobalPanelActionTypes.SET_PANEL_SIZE, payload: PanelType.medium });
            dispatch({ type: GlobalPanelActionTypes.SET_SHOW_PANEL, showPanel: true });
            dispatch({ type: GlobalPanelActionTypes.SET_HEADER, payload: `Deployment Assessment ID: ${fromDeploymentPage.params.id}` });
        } else dispatch({ type: GlobalPanelActionTypes.SET_SHOW_PANEL, showPanel: false });
    }, [location]);

    return (
        <>
            <Panel
                headerText={state.header}
                isOpen={state.showPanel}
                type={state.panelSize}
                isLightDismiss
                allowTouchBodyScroll
                onDismiss={() => {
                    history.push(routeMatch.url);
                    dispatch({ type: GlobalPanelActionTypes.CLEAR_ERROR });
                }}
                styles={PanelStyles}>
                <Switch>
                    <Route path={`${routeMatch.url}/NewException`}>
                        <NewExceptionFormContextProvider>
                            <NewExceptionForm exitUrl={routeMatch.url} />
                        </NewExceptionFormContextProvider>
                    </Route>
                    <Route path={`${routeMatch.url}/Exception/:id`}>
                        <ExceptionFormContextProvider>
                            <ExceptionForm exitUrl={routeMatch.url} />
                        </ExceptionFormContextProvider>
                    </Route>
                    <Route path={`${routeMatch.url}/fromDeployment/:id`}>
                        <FromDeployment />
                    </Route>
                </Switch>
            </Panel>
            <Switch>
                <Route path="/home">
                    <ExceptionRequestsTable />
                </Route>
            </Switch>
        </>
    );
};
