import React from "react";
import { Stack, Label, ITextFieldProps, Text, Shimmer } from "office-ui-fabric-react/lib/index";
import {
    ElxPrimaryButton,
    ElxTagContainer,
    TagSeverity,
    TagContainerOrientation,
    getCurrentUser,
    ElxTextField,
    IElxTextFieldProps,
    InputMessageTypes,
    ElxChoiceGroup,
    IElxChoiceGroupOption,
    ElxDialog,
    IElxDialogProps,
    ElxPersona,
    ElixirPersonaTypes,
    uxSendNotificationAction,
    getLogger,
    ITagData
} from "@elixir/fx";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GlobalPanelContext, GlobalPanelActionTypes } from "../contexts/GlobalPanelContext";
import { ExceptionFormContext, ExceptionFormActionTypes, ExceptionFormFields } from "../contexts/ExceptionFormContext";
import { ExceptionRequestClient, ServicesInformationClient, EventsRetrievalClient } from "../ApiClients/ApiClient";
import { Status, STATUS_MAP, IExceptionRequest, IService, IApproverNotes, IEvent, DeploymentSystem } from "../Types";
import {dateFormatter, isEmpty, isTooLong, rand, getAlias, isJustificationTooLong} from "../Utils";
import { DateRangeDisplay, ServiceDisplay } from "../Components";

const submissionText = "Are you sure you want to submit this Exception Request?";
const approvalText = "Are you sure you want to approve this Exception Request?";
const rejectionText = "Are you sure you want to reject this Exception Request?";

const FORM_MESSAGES = {
    EXCEPTIONREQUEST_NOTFOUND: {
        key: 0,
        text: "No Exception Request with given Id",
        severity: TagSeverity.Warning
    } as ITagData
};

export const ExceptionForm: React.FC<{ exitUrl: string }> = ({ exitUrl }) => {
    const exceptionRequestClient = new ExceptionRequestClient();
    const servicesInformationClient = new ServicesInformationClient();
    const eventsRetrievalClient = new EventsRetrievalClient();
    const { state, dispatch } = React.useContext(ExceptionFormContext);
    const globalDispatch = useDispatch();
    const panelContext = React.useContext(GlobalPanelContext);
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const { account, name, alias } = getCurrentUser();

    const [formState, setFormState] = React.useState<{ title?: string; justification?: string; rejectionReason?: string }>({
        title: "",
        justification: "",
        rejectionReason: "",
    });
    const [exceptionStatus, setExceptionStatus] = React.useState<string>(Status.Approved);
    const [serviceInfo, setServiceInfo] = React.useState<IService>();
    const [eventInfo, setEventInfo] = React.useState<IEvent>();

    const [titleTextField, setTitleTextField] = React.useState<IElxTextFieldProps>({});
    const [justificationTextField, setJustificationTextField] = React.useState<IElxTextFieldProps>({});
    const [rejectionTextField, setRejectionTextField] = React.useState<IElxTextFieldProps>({});
    const [confirmationDialog, setConfirmationDialog] = React.useState<IElxDialogProps>({
        hidden: true,
        dismissable: true,
        header: "Confirm Submit",
        primaryButtonText: "Submit",
        cancelButtonText: "Cancel",
    });
    const [confirmationText, setConfirmationText] = React.useState(submissionText);
    const [formMessages, setFormMessages] = React.useState<ITagData[]>([]);

    const initializeData = async () => {
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: true });

        try {
            const exceptionRequest = await exceptionRequestClient.getExceptionRequest(params.id);
            //Get full service information
            if (exceptionRequest) {
                const serviceId = exceptionRequest.services?.find(_val => true)?.serviceId;
                const service = serviceId ? await servicesInformationClient.getService(serviceId) : undefined;
                const eventId = exceptionRequest.eventIds?.find(_val => true);
                const eventDetails = eventId ? await eventsRetrievalClient.getEvent(eventId) : undefined;
                const approverAliases = exceptionRequest.approvers!.map((approverInfo) => getAlias(approverInfo.approverEmail));
                const requestorAlias = getAlias(exceptionRequest.requestorEmail);

                setEventInfo(eventDetails);
                setServiceInfo(service);
                setFormState({
                    title: `${DeploymentSystem.Ev2} - ${exceptionRequest.deploymentOfOrigin || "N/A"}`,
                    justification: exceptionRequest.businessJustification,
                });
                dispatch({ type: ExceptionFormActionTypes.UPDATE_REQUEST, payload: exceptionRequest });       
                
                
                dispatch({
                    type: ExceptionFormActionTypes.SET_OWNERSHIP,
                    payload: {
                        isApprover: approverAliases.indexOf(alias) >= 0,
                        isRequestor: alias === requestorAlias,
                    },
                });
                getLogger().event(ExceptionFormActionTypes.SET_OWNERSHIP, {
                    exceptionRequestId: params.id,
                    account,
                    approvers: exceptionRequest.approvers!.map((approverInfo) => approverInfo.approverEmail).toString(),
                    requestorEmail: exceptionRequest.requestorEmail,
                    accountAlias: alias,
                    approverAliases: approverAliases.toString(),
                    requestorAlias
                });
            } else {
                const message = {...FORM_MESSAGES.EXCEPTIONREQUEST_NOTFOUND, text: `Exception Request with Id: ${params.id} not found!`};
                addFormMessage(message);
            }
        } catch (e) {
            getLogger().error(e);
        }
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: false });
    };
    

    React.useEffect(() => {
        initializeData();
    }, [params.id]);

    const validateInputs = (currentFormState?: { title?: string; justification?: string; rejectionReason?: string }) => {
        const fields = Object.values(ExceptionFormFields);
        var inputsValid = true;

        fields.map((field) => {
            if (field === ExceptionFormFields.Title && (!currentFormState || typeof currentFormState.title !== "undefined")) {
                setTitleTextField({});
                const title = typeof currentFormState?.title === "undefined" ? formState.title : currentFormState.title;
                if (isEmpty(title)) {
                    setTitleTextField({ message: { content: "Title can not be empty.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
                if (isTooLong(title)) {
                    setTitleTextField({ message: { content: "Title must be less than 512 characters.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
            }

            if (field === ExceptionFormFields.Justification && (!currentFormState || typeof currentFormState.justification !== "undefined")) {
                setJustificationTextField({});
                const justification =
                    typeof currentFormState?.justification === "undefined" ? formState.justification : currentFormState.justification;
                if (isEmpty(justification)) {
                    setJustificationTextField({ message: { content: "Justification can not be empty.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
                if (isJustificationTooLong(justification)) {
                    setJustificationTextField({
                        message: { content: "Justification must be less than 5000 characters.", type: InputMessageTypes.Error },
                    });
                    inputsValid = false;
                }
            }

            if (
                field === ExceptionFormFields.Reason &&
                exceptionStatus === Status.Declined &&
                (!currentFormState || typeof currentFormState.rejectionReason !== "undefined")
            ) {
                setRejectionTextField({});
                const rejectionReason =
                    typeof currentFormState?.rejectionReason === "undefined" ? formState.rejectionReason : currentFormState.rejectionReason;
                if (isEmpty(rejectionReason)) {
                    setRejectionTextField({ message: { content: "Must give a reason when rejecting.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
                if (isTooLong(rejectionReason)) {
                    setRejectionTextField({ message: { content: "Reason must be less than 512 characters.", type: InputMessageTypes.Error } });
                    inputsValid = false;
                }
            }
        });

        return inputsValid;
    };

    const checkInputs = () => {
        if (!validateInputs()) return;
        else {
            if (state.exceptionRequest?.status === Status.PendingSubmission) {
                setConfirmationText(submissionText);
                setConfirmationDialog({ ...confirmationDialog, header: "Confirm Submit", hidden: false });
            } else if (exceptionStatus === Status.Approved) {
                setConfirmationText(approvalText);
                setConfirmationDialog({ ...confirmationDialog, header: "Confirm Approval", hidden: false });
            } else if (exceptionStatus === Status.Declined) {
                setConfirmationText(rejectionText);
                setConfirmationDialog({ ...confirmationDialog, header: "Confirm Rejection", hidden: false });
            }
        }
    };

    const onSubmit = async () => {
        setConfirmationDialog({ ...confirmationDialog, hidden: true });

        let updatedException: IExceptionRequest;
        if (state.exceptionRequest!.status === Status.PendingApproval) {
            let approverNote: IApproverNotes | null = null;
            let updatedApproversNotes = [...state.exceptionRequest!.approversNotes!];
            if (exceptionStatus === Status.Declined) {
                approverNote = { approverEmail: account, notes: formState.rejectionReason };
                updatedApproversNotes.push(approverNote);
            }
            updatedException = {
                ...state.exceptionRequest,
                status: exceptionStatus as Status,
                approvedBy: account,
                approversNotes: approverNote ? updatedApproversNotes : state.exceptionRequest?.approversNotes,
            } as IExceptionRequest;
        } else {
            updatedException = {
                ...state.exceptionRequest!,
                title: formState.title,
                businessJustification: formState.justification,
                status: Status.PendingApproval,
                requestorEmail: account,
                requestorName: name,
            } as IExceptionRequest;
        }

        let exceptionRequestClient = new ExceptionRequestClient();
        //panel loading.
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: true });
        //Run async tasks
        try {
            await exceptionRequestClient.updateExceptionRequest(state.exceptionRequest!.exceptionRequestId, updatedException);
            history.push(exitUrl);
            globalDispatch(
                uxSendNotificationAction({ message: `Exception Request with Id: ${state.exceptionRequest!.exceptionRequestId} has been updated` })
            );
        } catch (e) {
            getLogger().error(e);
        }
        panelContext.dispatch({ type: GlobalPanelActionTypes.SET_IS_LOADING, isLoading: false });
    };

    const viewerCanEdit = (): boolean => {
        return state.exceptionRequest?.status === Status.PendingSubmission;
    };

    const onTitleChange: ITextFieldProps["onChange"] = (event, newTitle) => {
        setFormState({ ...formState, title: newTitle });
        if (newTitle) validateInputs({ title: newTitle });
    };

    const onJustificationChange: ITextFieldProps["onChange"] = (event, newJustification) => {
        setFormState({ ...formState, justification: newJustification });
        if (newJustification) validateInputs({ justification: newJustification });
    };

    const onRejectionReasonChange: ITextFieldProps["onChange"] = (event, newReason) => {
        setFormState({ ...formState, rejectionReason: newReason });
        if (newReason) validateInputs({ rejectionReason: newReason });
    };

    const RenderStatus = (props: { status: Status }) => {
        return (
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <Label>Status: </Label>
                <span>{STATUS_MAP[props.status]}</span>
            </Stack>
        );
    };

    //Will remove canceled form message from the top of the form
    const onRemoveFormMessage = (message: ITagData) => {
        const newMessages = [...formMessages].filter(x => x.key !== message.key);
        setFormMessages(newMessages);
    }

    //Adds form messages to the top of the form if it does not already exist in the form.
    const addFormMessage = (message: ITagData) => {
        const newMessage = formMessages.find(x => x.key === message.key);
        if (newMessage) return;
        setFormMessages([...formMessages, message]);
    }

    const { exceptionRequest } = state;
    return (
        <form className="padding-1">
            <ElxTagContainer displayOrientation={TagContainerOrientation.Vertical} onRemoveTag={onRemoveFormMessage}  data={formMessages}/>
            {exceptionRequest ? (
                <>
                    {!viewerCanEdit() ? <Text variant="xxLarge">{exceptionRequest.title}</Text> : null}
                    <RenderStatus status={exceptionRequest.status} />
                    {viewerCanEdit() ? (
                        <ElxTextField
                            label="Title"
                            placeholder="Title of your exception request"
                            required={true}
                            value={formState.title}
                            message={titleTextField.message}
                            onChange={onTitleChange}
                        />
                    ) : null}
                    <br />
                    {viewerCanEdit() ? (
                        <ElxTextField
                            label="Justification"
                            required={true}
                            multiline={true}
                            rows={4}
                            placeholder="Enter reason for neeeding an exception"
                            value={formState.justification}
                            message={justificationTextField.message}
                            onChange={onJustificationChange}
                        />
                    ) : (
                        <Stack>
                            <Label>Justification:</Label>
                            <span>{exceptionRequest.businessJustification}</span>
                        </Stack>
                    )}
                    <br />
                    <Stack>
                        <Label>Exception Request Id:</Label>
                        <span>{exceptionRequest.exceptionRequestId}</span>
                    </Stack>
                    <br />
                    <Stack>
                        <Label>Request Date:</Label>
                        <span>{dateFormatter.format(new Date(exceptionRequest.requestDate))}</span>
                    </Stack>
                    <br />
                    {eventInfo ? (
                        <>
                            <Stack>
                                <Label>Event:</Label>
                                <span>{eventInfo.name}</span>
                            </Stack>
                            <br />
                        </>
                    ) : null}
                    <Stack>
                        <Label>Exception Period:</Label>
                        <DateRangeDisplay
                            startDate={dateFormatter.format(new Date(exceptionRequest.exceptionBeginsOn))}
                            endDate={dateFormatter.format(new Date(exceptionRequest.exceptionEndsOn))}
                        />
                    </Stack>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <Label>Deployment System:</Label>
                        <Text>{DeploymentSystem.Ev2}</Text>
                    </Stack>
                    <br />
                    <Stack>
                        <Label>Service:</Label>
                        <ServiceDisplay serviceName={serviceInfo?.serviceName} />
                    </Stack>
                    <br />
                    <Stack>
                        <Label>Subscriptions:</Label>
                        <Text>
                            {   //bug#30336022- Adding services length check so that the code doesn't error out when the service is not associated to a request.
                                exceptionRequest.services && exceptionRequest.services?.length > 0 &&
                                exceptionRequest.services![0].subscriptions!.map((subscription) => subscription.subscriptionId)
                                .map((id) => serviceInfo?.subscriptions!.find((sub) => sub.subscriptionId === id)?.subscriptionName)
                                .join(", ")}
                        </Text>
                    </Stack>
                    <br />
                    <Stack>
                        <Label>Deployment Location:</Label>
                        <Text>All regions</Text>
                    </Stack>
                    <br />
                    {exceptionRequest.approvedBy ? (
                        <>
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                <Label>Reviewed By:</Label>
                                <ElxPersona displayName="" userPrincipalName={exceptionRequest.approvedBy} type={ElixirPersonaTypes.Image} />
                                <span>{exceptionRequest.approvedBy}</span>
                            </Stack>
                            {exceptionRequest.approversNotes?.map((note, i) => {
                                if (i == 0)
                                    return (
                                        <Stack key={i}>
                                            <Label>Rejection reason: </Label> <Text>{note.notes}</Text>
                                        </Stack>
                                    );
                                else return <Text key={i}>{note.notes}</Text>;
                            })}
                            <br />
                        </>
                    ) : null}
                    {exceptionRequest.status === Status.PendingApproval || exceptionRequest.status === Status.PendingSubmission ? (
                        <Stack>
                            <Label>Approvers:</Label>
                            <ElxTagContainer
                                displayOrientation={TagContainerOrientation.Horizontal}
                                data={exceptionRequest.approvers!.map((approverInfo, i) => ({
                                    key: i,
                                    text: approverInfo.approverEmail!,
                                    severity: TagSeverity.Default,
                                }))}
                            />
                            <br />
                        </Stack>
                    ) : null}
                    {exceptionRequest.status === Status.PendingApproval ? (
                        <>
                            <ElxChoiceGroup
                                label="Select Action"
                                required={true}
                                selectedKey={exceptionStatus}
                                onChange={(e, option?: IElxChoiceGroupOption) => {
                                    setExceptionStatus(option!.key);
                                }}
                                disabled={!(exceptionRequest.status === Status.PendingApproval && state.isApprover)}
                                options={[
                                    { key: Status.Approved, text: "Approve" },
                                    { key: Status.Declined, text: "Reject" },
                                ]}
                            />
                            <ElxTextField
                                trackingContext={{ label: "Rejection reason" }}
                                required={true}
                                multiline={true}
                                disabled={exceptionStatus !== Status.Declined}
                                rows={4}
                                value={formState.rejectionReason}
                                message={rejectionTextField.message}
                                placeholder="Enter reason for rejecting exception"
                                onChange={onRejectionReasonChange}
                            />
                            <br />
                        </>
                    ) : null}
                    <ElxPrimaryButton
                        disabled={
                            exceptionRequest.status !== Status.PendingSubmission &&
                            !(exceptionRequest.status === Status.PendingApproval && state.isApprover)
                        }
                        text="Submit"
                        onClick={checkInputs}
                    />
                </>
            ) : (
                <Stack tokens={{ childrenGap: 10 }}>
                    {Array(20)
                        .fill(0)
                        .map((_num, i) => (
                            <Shimmer key={i} width={`${rand(60, 100)}%`} />
                        ))}
                </Stack>
            )}
            <ElxDialog
                {...confirmationDialog}
                onPrimaryButtonClick={onSubmit}
                onCancelButtonClick={() => setConfirmationDialog({ ...confirmationDialog, hidden: true })}
            >
                <Text>{confirmationText}</Text>
            </ElxDialog>
        </form>
    );
};
