/**
 *  format dates using the browsers locale, this is constructed
 * once and re-used for each call to onRender.  Calling date.toLocaleString() is
 * not recommended since it's slow and it constructs a new date formatter each time.
 */
export const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short"
});

export const camelToUpperCase = (str: string): string => {
    let snakeCase = str.replace(/[A-Z]/g, (letter) => `_${letter}`);
    snakeCase = snakeCase[0].toUpperCase() + snakeCase.slice(1);
    return snakeCase.split("_").join(" ");
};

export const isEmpty = (value?: string) => !(value && value.length);
export const isTooLong = (value?: string) => {
    if (value) return value?.length > 512;
    else return false;
};

export const isJustificationTooLong = (value?: string) => {
    if (value) return value?.length > 5000;
    else return false;
};

export const rand = (min: number, max:number) => {
    let randomNum = Math.random() * (max - min) + min;
    return Math.floor(randomNum);
}

export const updateDataAsync = <T extends Object>(store: T[], entryItem: T, id: string, key: keyof T): Promise<T> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            let index = 0;
            const locatedItem = store.find((item, i) => {
                index = i;
                return String(item[key]) === id;
            });
            if (locatedItem) {
                store[index] = entryItem;
                resolve(entryItem);
            } else reject("404");
        }, 1000);
    });
};

export const addDataAsync = <T>(store: T[], item: T): Promise<T> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            store.push(item);
            resolve(item);
        }, 1000);
    });
};

export const getDataAsync = <T>(data: T | undefined): Promise<T> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (data) resolve(data);
            else reject("404");
        }, 1000);
    });
};

export const findDataAsync = <T extends Object>(store: T[], id: string, key: keyof T): Promise<T> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const locatedItem = store.find((item) => {
                return String(item[key]) === id;
            });
            if (locatedItem) resolve(locatedItem);
            else reject("404");
        }, 1000);
    });
};

export const getAlias = (email:string | undefined): string => {
    if (!email) return "";

    const atPosition = email.indexOf("@"); 
    if (atPosition === -1) { 
        throw new Error(`${email} is not a valid email; @ is missing.`)/**Log something **/ 
    }

    const alias = email.substring(0, atPosition);
    return alias;
}
